<template>
  <div class="iphoneFrom">
    <div class="open_con">
      <div class="open_title">
        JEPaaS江湖产品合作申请
      </div>
      <div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-position="top"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="公司/个人名称"
            prop="corporateName"
          >
            <el-input v-model="ruleForm.corporateName" />
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="telPhone"
          >
            <el-input v-model="ruleForm.telPhone" />
          </el-form-item>
          <el-form-item label=" 产品">
            <el-input v-model="ruleForm.describe" />
          </el-form-item>
        </el-form>
      </div>
      <div
        class="buttonShen"
        @click="submitForm('ruleForm')"
      >
        提交申请
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IphoneFrom',
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      prohibit: true,
      ruleForm: {
        corporateName: '',
        telPhone: '',
        describe: '',
      },
      rules: {
        corporateName: [
          { required: true, message: '请输入您的公司或个人名称 ', trigger: 'blur' },
        ],
        telPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  updated() { },
  methods: {
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/addInfoForOpportunity',
              data: {
                type: 'JH',
                corporateName: self.ruleForm.corporateName,
                telPhone: self.ruleForm.telPhone,
                describe: self.ruleForm.describe,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.corporateName = '';
              self.ruleForm.telPhone = '';
              self.ruleForm.describe = '';
              this.dialogFormVisible = false;
            }).catch(() => {
              self.prohibit = true;
              this.$message.error('提交失败，请重试。');
            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>
 <style lang="less" >
.demo-ruleForm {
  padding: 0 50px 40px 50px;
  text-align: left;
  .el-form-item {
    padding: 20px 50px;
  }
  input {
    // border: 0;
    width: 100%;
    height: 60px;
    background: rgba(238, 238, 238, 0.3);
    // opacity: 0.3;
    border-radius: 23px;
    color: rgb(109, 107, 107);
  }
}
</style>
<style lang="less" scoped>
.iphoneFrom {
  text-align: center;
  .buttonShen {
    cursor: pointer;
    width: 240px;
    margin: 0px auto;
    height: 60px;
    background: rgba(255, 48, 65, 1);
    box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
    border-radius: 30px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(254, 255, 255, 1);
    text-align: center;
    position: relative;
    &:after {
      content: "";
      width: 240px;
      height: 60px;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 30px;
      z-index: -1;
      transition: background 0.7s;
    }
    &:hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
  }

  .open_con {
    // height: 470px;
    .open_title {
      padding: 120px 0 60px 0;
      font-size: 100px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>
